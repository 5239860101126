<template>
  <div>
    <el-menu
      :default-active="this.$route.path"
      mode="horizontal"
      text-color="#544646"
      active-text-color="#fb3434"
      @select="handleSelect"
    >
      <el-menu-item v-for="(item, i) in navList" :index="item.path" :key="i">{{item.title}}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        { path: '/index', title: '首页' },
        { path: '/orderAdmin/purchase', title: '现在下单' },
        { path: '/notice', title: '系统公告' }
        // { path: '/join', title: '分站开通' },
        // { path: 'api', title: 'API接口' }
      ]
    }
  },
  methods: {
    handleSelect (index) {
      switch (index) {
        case '/index':
          this.$router.push('/index')
          break
        case '/orderAdmin/purchase':
          this.$router.push('/orderAdmin/purchase')
          break
        case '/notice':
          this.$router.push('/notice')
          break
        // case 'api':
        //   window.open(this.$store.getters.getSiteInfo.api_service, '_blank')
        //   break
      }
    }
  }
}
</script>

<style scoped src="styles/components/navbarmenu.css">
</style>
